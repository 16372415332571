<template>
  <SplitGrid
    ref="outerGrid"
    :gutter-size="3"
    direction="row"
    class="flex flex-1 w-full h-full overflow-hidden bg-neutral-gray"
    :min-size="gridBottomMin"
    @drag-end="onDragEnd"
  >
    <SplitGridArea :size-unit="gridTopUnit" :size-value="gridTop">
      <slot name="viewer" />
    </SplitGridArea>
    <SplitGridGutter class="z-10" />
    <SplitGridArea
      :size-unit="gridBottomUnit"
      :size-value="gridBottom"
      class="relative z-10 bg-gray-800"
    >
      <slot name="timeline" />
    </SplitGridArea>
  </SplitGrid>
</template>

<script>
import { SplitGrid, SplitGridArea, SplitGridGutter } from 'vue-split-grid'

export default {
  name: 'LayoutPanels',
  components: {
    SplitGrid,
    SplitGridArea,
    SplitGridGutter
  },

  props: {
    showTimeline: {
      type: Boolean,
      default: true
    },

    showLeft: {
      type: Boolean,
      default: false
    },

    showRight: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      baseL: 20,
      baseC: 60,
      baseR: 20,

      baseTop: 78,
      baseBottom: 22,

      gridSize: null
    }
  },

  computed: {
    colSizeMin() {
      return 45
    },

    gridL() {
      if (this.showLeft) return this.baseL
      return 0
    },

    gridC() {
      if (this.showLeft && this.showRight) return this.baseC
      if (this.showLeft) return 100 - this.baseL
      if (this.showRight) return 100 - this.baseR
      return 100
    },

    gridR() {
      if (this.showRight) return this.baseR
      return 0
    },

    gridTopUnit() {
      if (this.showTimeline) return '%'
      return 'fr'
    },

    gridBottomUnit() {
      if (this.showTimeline) return '%'
      return 'px'
    },

    gridTop() {
      if (this.showTimeline) return this.baseTop
      return 1
    },

    gridBottom() {
      if (this.showTimeline) return this.baseBottom
      return 0
    },

    gridBottomMin() {
      return 32
    },

    showRightGutter() {
      return this.showRight
    },

    showLeftGutter() {
      return this.showLeft
    }
  },

  mounted() {
    this.checkForStoredLayoutSizes()
  },

  methods: {
    onDragEnd(event) {
      this.$emit('grid-resized', event)
      this.updateStoredLayout()
    },

    updateStoredLayout() {
      let outer = this.$refs.outerGrid
      // let inner = this.$refs.innerGrid

      let sizes = {
        outer: outer.$el.style.gridTemplateRows
        // inner: inner.$el.style.gridTemplateColumns
      }

      this.$store.dispatch('preferences/updateEditorLayoutSizes', {
        sizes: sizes
      })
    },

    checkForStoredLayoutSizes() {
      let stored = this.$store.getters['preferences/editorLayoutSizes']
      if (stored === null) return

      // if (stored.inner !== undefined) {
      //   let parts = stored.inner.split(' ')

      //   if (parts.length !== 5) return

      //   // Make sure the BaseL + BaseR add up to around 100%
      //   let baseL = Number(parts[0].replace('%', ''))
      //   let baseC = Number(parts[2].replace('%', ''))
      //   let baseR = Number(parts[4].replace('%', ''))

      //   if (!isNaN(baseL) && !isNaN(baseC) && !isNaN(baseR)) {
      //     this.baseL = baseL
      //     this.baseC = baseC
      //     this.baseR = baseR
      //   }
      // }

      if (stored.outer !== undefined) {
        let parts = stored.outer.split(' ')
        if (parts.length !== 3) return

        let top = Number(parts[0].replace('%', '').replace('px', ''))
        let bottom = Number(parts[2].replace('%', '').replace('px', ''))

        if (!isNaN(top) && !isNaN(bottom)) {
          this.baseTop = top
          this.baseBottom = bottom
        }
      }
    }
  }
}
</script>
